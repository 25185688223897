import { createReducer } from '@reduxjs/toolkit';
import { addLocalSharedStream, addSendingSharedStream, addSharedStream, removeLocalSharedStream, removeSharedStream, setLocalMediaStream, setMembers, setRemoteMediaStream, setVideoLink } from '../actions/call';

export type CallSharedScreen = {
    streamId: string;
    mediaStream?: MediaStream;
};

export type CallMember = {
    username: string;
    streamId: string;
    memberId: string;
    mediaStream?: MediaStream;
    hasVideo: boolean;
    hasAudio: boolean;
    sharedScreens: CallSharedScreen[];
    sendingSharedScreens: CallSharedScreen[];
};

export interface CallState {
    joinedCall: boolean;
    members: CallMember[];
    localMediaStream?: MediaStream;
    localSharedStreams: { [id: string]: MediaStream };
    videoLink?: string;
}

const defaultState: CallState = {
    joinedCall: true,
    members: [
        /* {streamId: '1', username: 'Timo', hasVideo: true, hasAudio: true, sharedScreens: [{streamId: '101'}, {streamId: '102'}]},
        {streamId: '2', username: 'Tomi', hasVideo: true, hasAudio: true, sharedScreens: []},
        {streamId: '3', username: 'Mito', hasVideo: false, hasAudio: true, sharedScreens: [{streamId: '103'}]},
        {streamId: '4', username: 'MotMot', hasVideo: false, hasAudio: true, sharedScreens: [{streamId: '104'}]},
        {streamId: '5', username: 'TomTom', hasVideo: false, hasAudio: true, sharedScreens: [{streamId: '105'}]},
        {streamId: '6', username: 'Tomi', hasVideo: true, hasAudio: true, sharedScreens: []},
        {streamId: '7', username: 'Mito', hasVideo: false, hasAudio: true, sharedScreens: [{streamId: '106'}]},
        {streamId: '8', username: 'MotMot', hasVideo: false, hasAudio: true, sharedScreens: [{streamId: '107'}]},
        {streamId: '9', username: 'TomTom', hasVideo: false, hasAudio: true, sharedScreens: [{streamId: '108'}]},
        {streamId: '10', username: 'Timo', hasVideo: true, hasAudio: true, sharedScreens: [{streamId: '109'}, {streamId: '110'}]},
        {streamId: '11', username: 'Tomi', hasVideo: true, hasAudio: true, sharedScreens: []},
        {streamId: '12', username: 'Mito', hasVideo: false, hasAudio: true, sharedScreens: [{streamId: '111'}]},
        {streamId: '13', username: 'MotMot', hasVideo: false, hasAudio: true, sharedScreens: [{streamId: '112'}]},
        {streamId: '14', username: 'TomTom', hasVideo: false, hasAudio: true, sharedScreens: [{streamId: '113'}]},
        {streamId: '15', username: 'Tomi', hasVideo: true, hasAudio: true, sharedScreens: []},
        {streamId: '16', username: 'Mito', hasVideo: false, hasAudio: true, sharedScreens: [{streamId: '114'}]},
        {streamId: '17', username: 'MotMot', hasVideo: false, hasAudio: true, sharedScreens: [{streamId: '115'}]},
        {streamId: '18', username: 'TomTom', hasVideo: false, hasAudio: true, sharedScreens: [{streamId: '116'}]} */
    ],
    localSharedStreams: {},
};

export const callReducer = createReducer(defaultState, (builder) =>
    builder
        .addCase(setLocalMediaStream, (state, action) => {
            state.localMediaStream = action.payload;
        })
        .addCase(setRemoteMediaStream, (state, action) => {
            for (let i = 0; i < state.members.length; i++) {
                const member = state.members[i];
                if (member.streamId === action.payload.streamId) {
                    state.members[i].mediaStream = action.payload.mediaStream;
                    console.log('FOUND!');
                }
                for (let j = 0; j < member.sharedScreens.length; j++) {
                    const sharedScreen = member.sharedScreens[j];
                    if (sharedScreen.streamId === action.payload.streamId) {
                        console.log('FOUND SHARED!');
                        state.members[i].sharedScreens[j].mediaStream = action.payload.mediaStream;
                    }
                }
            }
        })
        .addCase(setMembers, (state, action) => {
            state.members = action.payload.members;
        })
        .addCase(addSharedStream, (state, action) => {
            for (let i = 0; i < state.members.length; i++) {
                const member = state.members[i];
                if (member.memberId === action.payload.memberId) {
                    console.log('addSharedStream FOUND member!');
                    member.sharedScreens.push({
                        streamId: action.payload.streamId,
                    });
                }
            }
        })
        .addCase(addSendingSharedStream, (state, action) => {
            for (let i = 0; i < state.members.length; i++) {
                const member = state.members[i];
                if (member.memberId === action.payload.memberId) {
                    console.log('sendingSharedScreens FOUND member!');
                    member.sendingSharedScreens.push({
                        streamId: action.payload.streamId,
                    });
                }
            }
        })
        .addCase(removeSharedStream, (state, action) => {
            for (let i = 0; i < state.members.length; i++) {
                const member = state.members[i];
                console.log('member');
                if (member.memberId === action.payload.memberId) {
                    console.log('found member');
                    for (let j = 0; j < member.sharedScreens.length; j++) {
                        console.log('check', member.sharedScreens[j].streamId, action.payload.streamId);
                        if (member.sharedScreens[j].streamId === action.payload.streamId) {
                            console.log('found and removed stream!');
                            member.sharedScreens.splice(j, 1);
                            return;
                        }
                    }
                }
            }
        })
        .addCase(addLocalSharedStream, (state, action) => {
            state.localSharedStreams[action.payload.streamId] = action.payload.mediaStream;
        })
        .addCase(removeLocalSharedStream, (state, action) => {
            if (state.localSharedStreams[action.payload.streamId]) {
                delete state.localSharedStreams[action.payload.streamId];
            }
        })
        .addCase(setVideoLink, (state, action) => {
            state.videoLink = action.payload;
        }),
);
