import React from 'react';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface Props {}

const PharmacyLink: React.FC<Props> = (_props: Props) => {
    let videoLink = useSelector((state: RootState) => state.call.videoLink);
    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: '#053855',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div style={{ padding: 20, flexDirection: 'column', display: 'flex', color: '#ffffff' }}>
                Klicken Sie den "Teilnehmen"-Button falls Sie auf diesem Computer den Anruf durchführen möchten. Alternativ können Sie den QR-Code mit ihrem Handy scannen und von dort den Videoanruf durchführen.
                <a href={videoLink} style={{ textDecoration: 'none', marginTop: 20 }}>
                    <div style={{ borderRadius: 8, backgroundColor: '#ffffff', borderStyle: 'solid', borderWidth: 1, borderColor: '#000000', width: 200, height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#000000' }}>Teilnehmen</div>
                </a>
                <br />
                oder scannen:
                {videoLink && (
                    // @ts-ignore
                    <QRCode style={{ height: 'auto', width: 256, marginTop: 20, padding: 20, backgroundColor: '#ffffff' }} value={videoLink} />
                )}
            </div>
        </div>
    );
};

export default PharmacyLink;
