import EventEmitter from 'events';
import ShortUniqueId from 'short-unique-id';

export type LocalVideoConstraints = { videoDeviceId?: string };
export type LocalAudioConstraints = {
    audioDeviceId?: string;
    echoCancellation?: boolean;
    noiseSuppression?: boolean;
};

const stdVideoConstraints = {
        video: { width: { ideal: 1920 }, height: { ideal: 1080 } },
    },
    stdAudioConstraints = { audio: { echoCancellation: true } };

export type DeviceInfo = {
    label: string;
    deviceId?: string;
};

export type DeviceList = {
    videoDevices: DeviceInfo[];
    audioInputDevices: DeviceInfo[];
    audioOutputDevices: DeviceInfo[];
};

const uid = new ShortUniqueId({ length: 12 });

export type FacingMode = 'user' | 'environment';

export class CallController extends EventEmitter {
    public turnUsername: string | undefined;
    public turnPassword: string | undefined;

    // eslint-disable-next-line no-undef
    localVideoConstraints?: MediaStreamConstraints = { ...stdVideoConstraints };
    // eslint-disable-next-line no-undef
    localAudioConstraints?: MediaStreamConstraints = { ...stdAudioConstraints };

    peerConnections: { [id: string]: RTCPeerConnection } = {};
    mainPeerConnection?: RTCPeerConnection;

    localStream?: MediaStream;
    localScreens: { [id: string]: MediaStream } = {};

    remoteCandidates: { connectionId: string; iceCandidate: any }[] = [];

    lastFacingMode: FacingMode | undefined;

    callStart?: Date;

    getIceConfiguration() {
        return {
            iceServers: [
                { urls: 'stun:turn.vitus-app.com:3478' },
                {
                    urls: 'turn:turn.vitus-app.com:3478',
                    username: this.turnUsername,
                    credential: this.turnPassword,
                },
            ],
        };
    }

    async getDeviceList(): Promise<DeviceList> {
        const devices: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices(),
            result: DeviceList = {
                videoDevices: [],
                audioInputDevices: [],
                audioOutputDevices: [],
            };

        result.videoDevices = devices
            .filter((device) => device.kind === 'videoinput')
            .map(
                (entry) =>
                    ({
                        label: entry.label,
                        deviceId: entry.deviceId,
                    } as DeviceInfo),
            );
        result.audioInputDevices = devices
            .filter((device) => device.kind === 'audioinput')
            .map(
                (entry) =>
                    ({
                        label: entry.label,
                        deviceId: entry.deviceId,
                    } as DeviceInfo),
            );
        result.audioOutputDevices = devices
            .filter((device) => device.kind === 'audiooutput')
            .map(
                (entry) =>
                    ({
                        label: entry.label,
                        deviceId: entry.deviceId,
                    } as DeviceInfo),
            );

        return result;
    }

    async setLocalDevices(facingMode?: FacingMode) {
        try {
            this.lastFacingMode = facingMode;
            let videoConstraints: any = { ...this.localVideoConstraints };
            if (facingMode) {
                if (!videoConstraints.video) {
                    videoConstraints.video = {};
                }
                videoConstraints.video.facingMode = facingMode;
            }
            this.localStream = await navigator.mediaDevices.getUserMedia({
                ...videoConstraints,
                ...this.localAudioConstraints,
            });
            console.log('this.localStream', this.localStream);
            this.emit('localStream', { mediaStream: this.localStream });
            return 'allowed';
        } catch (ex) {
            return 'notAllowed';
        }
    }

    async switchLocalDevice() {
        if (this.lastFacingMode === 'environment') {
            this.lastFacingMode = 'user';
        } else {
            this.lastFacingMode = 'environment';
        }
        await this.setLocalDevices(this.lastFacingMode);

        if (this.localStream) {
            this.localStream.getTracks().forEach((track) => {
                console.log('local track', track);
                let sender = this.mainPeerConnection?.getSenders().find((s) => {
                    if (!s.track) {
                        return undefined;
                    }
                    return s.track.kind === track.kind;
                });
                if (sender) {
                    sender.replaceTrack(track);
                }
            });
        }
    }

    async shareScreen() {
        const streamId = uid();
        // prettier-ignore
        // eslint-disable-next-line no-lone-blocks
        {
            // @ts-ignore
            this.localScreens[streamId] = await navigator.mediaDevices.getDisplayMedia({ cursor: true, video: { resizeMode: 'none', width: 3840, height: 2160 } });
        }

        if (this.localScreens[streamId]) {
            console.log('streamId', streamId);

            return {
                streamId,
                mediaStream: this.localScreens[streamId],
            };
        }
        return undefined;
    }

    stopStream(streamId: string) {
        const stream = this.localScreens[streamId];
        console.log('stopStream', stream);
        if (stream) {
            stream.getTracks().forEach((track) => track.stop());
        }
    }

    closeAll() {
        this.emit('callEnded', { callStart: this.callStart });
        this.localStream?.getTracks().forEach((track) => track.stop());
        for (const [_key, value] of Object.entries(this.peerConnections)) {
            value.close();
        }
    }

    createPeerConnection(connectionId: string) {
        const connection = new RTCPeerConnection(this.getIceConfiguration());
        this.mainPeerConnection = connection;
        this.peerConnections[connectionId] = connection;

        const remoteStream = new MediaStream();
        this.emit('remoteStream', {
            connectionId,
            remoteStream,
        });

        connection.addEventListener('icecandidate', (event) => {
            // console.log('listener icecandiate');
            this.emit('icecandidate', {
                connectionId,
                candidate: event.candidate,
            });
        });
        connection.addEventListener('connectionstatechange', (_event) => {
            console.log('listener connectionstatechange', connection.connectionState);

            if (connection.connectionState === 'connected') {
                this.callStart = new Date();
            }

            this.emit('connectionStateChanged', {
                connectionId,
                connectionState: connection.connectionState,
            });
        });
        connection.addEventListener('iceconnectionstatechange', (_event) => {
            console.log('listener iceconnectionstatechange', connection.iceConnectionState);
        });
        connection.addEventListener('track', (event) => {
            console.log('track remoteMedia!', event.track.kind);
            this.emit('remoteStreamTrack', {
                connectionId,
                track: event.track,
            });
        });
        return connection;
    }

    async createOffer(connectionId: string, streamId?: string) {
        const connection = this.createPeerConnection(connectionId);
        if (streamId) {
            console.log('Create offer with streamId', streamId, this.localScreens);
            const stream = this.localScreens[streamId];
            if (stream) {
                console.log('found stream', streamId);
                stream.getTracks().forEach((track) => connection.addTrack(track));
            }
        } else {
            this.localStream!.getTracks().forEach((track) => connection.addTrack(track));
        }
        const offer = await this.peerConnections[connectionId].createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
        });

        //    console.log("before OFFER!!!", offer.sdp);

        // @ts-ignore
        // offer.sdp = CodecsHandler.preferCodec(offer.sdp, 'h264');

        //    console.log("after OFFER!!!", offer.sdp);

        await this.peerConnections[connectionId].setLocalDescription(offer);
        return offer;
    }

    async createAnswer(connectionId: string, offer: any, onlyReceive?: boolean) {
        const connection = this.createPeerConnection(connectionId);
        if (!onlyReceive) {
            this.localStream!.getTracks().forEach((track) => connection.addTrack(track));
        }

        await connection.setRemoteDescription(new RTCSessionDescription(offer));

        const answer = await connection.createAnswer();
        await connection.setLocalDescription(answer);

        this.processCandidates();

        return answer;
    }

    async receivedAnswer(connectionId: string, answer: any) {
        console.log('receivedAnswer', connectionId);
        const connection = this.peerConnections[connectionId];
        if (connection) {
            console.log('connection found');
            console.log('add remoteDescription');
            await connection.setRemoteDescription(new RTCSessionDescription(answer));
            this.processCandidates();
        }
    }

    async receivedIceCandidate(connectionId: string, iceCandidate: any) {
        console.log('receivedIceCandidate', iceCandidate);
        const connection = this.peerConnections[connectionId];
        if (connection) {
            if (connection.remoteDescription == null || this.remoteCandidates.length > 0) {
                this.remoteCandidates.push({
                    connectionId,
                    iceCandidate,
                });
                return;
            }
            try {
                await connection.addIceCandidate(iceCandidate);
            } catch (ex) {
                console.log(ex);
            }
        } else {
            this.remoteCandidates.push({
                connectionId,
                iceCandidate,
            });
        }
    }

    processCandidates() {
        console.log('!!!processCandidates', this.remoteCandidates.length);
        for (let i = 0; i < this.remoteCandidates.length; i++) {
            const candidate = this.remoteCandidates[i];
            try {
                this.peerConnections[candidate.connectionId].addIceCandidate(candidate.iceCandidate);
            } catch (ex) {
                console.log(ex);
            }
        }
        this.remoteCandidates = [];
    }

    toggleVideo() {
        if (this.localStream) {
            this.localStream!.getTracks().forEach((track) => {
                if (track.kind === 'video') {
                    track.enabled = !track.enabled;
                    this.emit('videoToggled', { enabled: track.enabled });
                }
            });
        }
    }

    toggleAudio() {
        if (this.localStream) {
            this.localStream!.getTracks().forEach((track) => {
                if (track.kind === 'audio') {
                    track.enabled = !track.enabled;
                    this.emit('audioToggled', { enabled: track.enabled });
                }
            });
        }
    }

    streamIdExists(streamId: string): boolean {
        return this.localScreens[streamId] !== undefined;
    }
}

const callController = new CallController();
export default callController;
