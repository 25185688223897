import { createReducer } from '@reduxjs/toolkit';
import { setAudioInDevices, setAudioOutDevices, setLocalAudioEnabled, setLocalVideoEnabled, setSelectedAudioInDeviceId, setSelectedAudioOutDeviceId, setSelectedVideoDeviceId, setVideoDevices } from '../actions/devices';
import { getCookie } from '../../helpers/cookies';

export type ResolutionContraint = {
    video: { width: { exact: number }; height: { exact: number } };
};

export const qvgaConstraints: ResolutionContraint = {
    video: { width: { exact: 320 }, height: { exact: 240 } },
};

export const vgaConstraints: ResolutionContraint = {
    video: { width: { exact: 640 }, height: { exact: 480 } },
};

export const hdConstraints: ResolutionContraint = {
    video: { width: { exact: 1280 }, height: { exact: 720 } },
};

export const fullHdConstraints: ResolutionContraint = {
    video: { width: { exact: 1920 }, height: { exact: 1080 } },
};

export const televisionFourKConstraints: ResolutionContraint = {
    video: { width: { exact: 3840 }, height: { exact: 2160 } },
};

export const cinemaFourKConstraints: ResolutionContraint = {
    video: { width: { exact: 4096 }, height: { exact: 2160 } },
};

export type DeviceInfo = {
    label: string;
    deviceId?: string;
};

export interface DevicesState {
    videoDevices: DeviceInfo[];
    audioInDevices: DeviceInfo[];
    audioOutDevices: DeviceInfo[];
    selectedVideoDeviceId?: string;
    selectedAudioInDeviceId?: string;
    selectedAudioOutDeviceId?: string;
    localAudioEnabled: boolean;
    localVideoEnabled: boolean;
}

const defaultState: DevicesState = {
    videoDevices: [],
    audioInDevices: [],
    audioOutDevices: [],
    selectedVideoDeviceId: getCookie('videoDeviceId'),
    selectedAudioInDeviceId: getCookie('audioInDeviceId'),
    selectedAudioOutDeviceId: getCookie('audioOutDeviceId'),
    localAudioEnabled: true,
    localVideoEnabled: true,
};

export const devicesReducer = createReducer(defaultState, (builder) =>
    builder
        .addCase(setVideoDevices, (state, action) => {
            state.videoDevices = action.payload;
        })
        .addCase(setAudioInDevices, (state, action) => {
            state.audioInDevices = action.payload;
        })
        .addCase(setAudioOutDevices, (state, action) => {
            state.audioOutDevices = action.payload;
        })
        .addCase(setSelectedVideoDeviceId, (state, action) => {
            state.selectedVideoDeviceId = action.payload;
        })
        .addCase(setSelectedAudioInDeviceId, (state, action) => {
            state.selectedAudioInDeviceId = action.payload;
        })
        .addCase(setSelectedAudioOutDeviceId, (state, action) => {
            state.selectedAudioOutDeviceId = action.payload;
        })
        .addCase(setLocalVideoEnabled, (state, action) => {
            state.localVideoEnabled = action.payload;
        })
        .addCase(setLocalAudioEnabled, (state, action) => {
            state.localAudioEnabled = action.payload;
        }),
);
