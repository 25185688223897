import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import queryString from 'query-string';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import connectionController from './shared/controllers/ConnectionController';
import { setSharedStore } from './shared/store';
import { setShowWelcome } from './store/actions/view';
import callController from './shared/controllers/CallController';
import PharmacyLink from './components/views/pharmacyLink';

const params = queryString.parse(window.location.search);

setSharedStore(store);

const type = params.type,
    pid = params.pid as string,
    uid = params.uid as string;

if (typeof params.tk === 'string') {
    connectionController.loginToken = params.tk;
}
if (typeof params.cr === 'string') {
    connectionController.chatRoomId = params.cr;
}

if (connectionController.loginToken && connectionController.chatRoomId) {
    setTimeout(async () => {
        store.dispatch(setShowWelcome(false));
        await callController.setLocalDevices('user');
        // connectionController.enterChatRoom();
    });
}

console.log('type', type);

if (type === 'cr' && pid && uid) {
    connectionController.createChatRoom(pid, uid);
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <PharmacyLink />
            </Provider>
        </React.StrictMode>,
    );
} else {
    const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <App />
            </Provider>
        </React.StrictMode>,
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
