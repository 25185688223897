import { CallMember } from '../reducers/call';
import { store } from '../index';

export function getMemberFromStreamId(streamId: string): CallMember | undefined {
    const callState = store.getState().call;
    for (let i = 0; i < callState.members.length; i++) {
        const member = callState.members[i];
        if (member.streamId === streamId) {
            return member;
        }
        for (let j = 0; j < member.sharedScreens.length; j++) {
            const sharedScreen = member.sharedScreens[j];
            if (sharedScreen.streamId === streamId) {
                return member;
            }
        }
        for (let j = 0; j < member.sendingSharedScreens.length; j++) {
            const sharedScreen = member.sendingSharedScreens[j];
            if (sharedScreen.streamId === streamId) {
                return member;
            }
        }
    }
    return undefined;
}

export function composeStreamId(streamId1: string, streamId2: string) {
    if (streamId1 < streamId2) {
        return `${streamId1}_${streamId2}`;
    }
    return `${streamId2}_${streamId1}`;
}
