export function formattedDate(date) {
    return `${date.toLocaleString()}`;
}

export function toggleFullScreen(element) {
    if (document.fullscreenElement) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    } else {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
            // for IE11 (remove June 15, 2022)
            element.msRequestFullscreen();
        } else if (element.webkitRequestFullscreen) {
            // iOS Safari
            element.webkitRequestFullscreen();
        }
    }
}

export function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'windows';
    }

    if (/android/i.test(userAgent)) {
        return 'android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'ios';
    }

    return 'unknown';
}

export function secondsAsTime(seconds) {
    let partMinutes = '' + Math.floor(seconds / 60),
        partSeconds = '' + (seconds % 60);

    if (partMinutes.length === 1) {
        partMinutes = '0' + partMinutes;
    }
    if (partSeconds.length === 1) {
        partSeconds = '0' + partSeconds;
    }

    return partMinutes + ':' + partSeconds;
}
