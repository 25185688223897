import { createAction } from '@reduxjs/toolkit';
import { DeviceInfo } from '../reducers/devices';

export const setVideoDevices = createAction<DeviceInfo[]>('devices/setVideoDevices');

export const setAudioInDevices = createAction<DeviceInfo[]>('devices/setAudioInDevices');

export const setAudioOutDevices = createAction<DeviceInfo[]>('devices/setAudioOutDevices');

export const setSelectedVideoDeviceId = createAction<string | undefined>('devices/setSelectedVideoDeviceId');

export const setSelectedAudioInDeviceId = createAction<string | undefined>('devices/setSelectedAudioInDeviceId');

export const setSelectedAudioOutDeviceId = createAction<string | undefined>('devices/setSelectedAudioOutDeviceId');

export const setLocalVideoEnabled = createAction<boolean>('devices/setLocalVideoEnabled');

export const setLocalAudioEnabled = createAction<boolean>('devices/setLocalAudioEnabled');
