import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Fade from '../transitions/fade';
import SlideIn from '../transitions/slideIn';
import { setShowWelcome } from '../../store/actions/view';
import callController from '../../shared/controllers/CallController';
import connectionController from '../../shared/controllers/ConnectionController';

interface Props {
    width?: number;
    visible: boolean;
}

const Welcome: React.FC<Props> = (props: Props) => {
    const [welcomeVisible, setWelcomeVisible] = useState(true),
     dispatch = useDispatch();
    return (
        <SlideIn
            visible={props.visible}
            style={{
                backgroundColor: '#053855',
                color: '#ffffff',
                width: props.width,
                flex: props.width ? undefined : 1,
                paddingTop: 40,
                position: 'relative',
                zIndex: 20,
            }}
            width={props.width}
        >
            {' '}
            <Fade
                visible={!welcomeVisible}
                style={{
                    position: 'absolute',
                    top: 40,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
            >
                <div>Erfolgreich verbunden</div>
            </Fade>
            <Fade
                visible={welcomeVisible}
                style={{
                    position: 'absolute',
                    top: 40,
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
            >
                <div>
                    Willkommen
                    <br />
                    im amamed Video-Chat
                    <br />
                    <div style={{ marginTop: 40 }}>Bitte Code eingeben</div>
                    <button
                        onClick={() => {
                            setWelcomeVisible(false);
                            setTimeout(async () => {
                                dispatch(setShowWelcome(false));
                                await callController.setLocalDevices();
                                connectionController.enterChatRoom();
                            }, 2000);
                        }}
                    >
                        click
                    </button>
                </div>
            </Fade>
        </SlideIn>
    );
};

export default Welcome;
