import React, { CSSProperties, useMemo, useRef } from 'react';
import { Transition } from 'react-transition-group';

const duration = 300;

interface Props {
    visible: boolean;
    style: CSSProperties;
    children?: React.ReactNode;
    removeOnExited?: boolean;
    duration?: number;
}

const transitionStyles: { [id: string]: CSSProperties } = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
},

 Fade: React.FC<Props> = (props: Props) => {
    const nodeRef = useRef(null),

     defaultStyle = useMemo(() => ({
        transition: `opacity ${props.duration || duration}ms ease-in-out`,
    }), [props.duration]);

    return (
        <Transition nodeRef={nodeRef} in={props.visible} timeout={duration}>
            {(state) => (props.removeOnExited && state === 'exited' ? null : (
                <div
                    ref={nodeRef}
                    style={{
                        ...props.style,
                        ...defaultStyle,
                        ...transitionStyles[state],
                    }}
                >
                    {props.children}
                </div>
            ))
            }
        </Transition>
    );
};

export default Fade;
