import { createAction } from '@reduxjs/toolkit';
import { CallMember } from '../reducers/call';

export const setLocalMediaStream = createAction<MediaStream>('call/setLocalMediaStream');
export const setRemoteMediaStream = createAction<{ streamId: string; mediaStream?: MediaStream }>('call/setRemoteMediaStream');

export const setMembers = createAction<{ members: CallMember[] }>('call/setMembers');

export const addSharedStream = createAction<{ memberId: string; streamId: string }>('call/addSharedStream');
export const addSendingSharedStream = createAction<{ memberId: string; streamId: string }>('call/addSendingSharedStream');
export const removeSharedStream = createAction<{ memberId: string; streamId: string }>('call/removeSharedStream');
export const removeSendingSharedStream = createAction<{ memberId: string; streamId: string }>('call/removeSendingSharedStream');

export const addLocalSharedStream = createAction<{ streamId: string; mediaStream: MediaStream }>('call/addLocalSharedStream');
export const removeLocalSharedStream = createAction<{ streamId: string }>('call/removeLocalSharedStream');

export const setVideoLink = createAction<string>('call/setVideoLink');
