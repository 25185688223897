// @ts-ignore
import JsonSocket from 'jcconnectweb';
import util from 'util';
import callController from './CallController';
import { CallMember } from '../store/reducers/call';
import { addLocalSharedStream, addSendingSharedStream, addSharedStream, removeLocalSharedStream, removeSendingSharedStream, removeSharedStream, setMembers, setVideoLink } from '../store/actions/call';
import { store } from '../store';
import { composeStreamId } from '../store/functions/call';

class ConnectionController {
    jsonSocket: JsonSocket;
    keepAliveInterval?: ReturnType<typeof setInterval>;

    loginToken: string = '';
    chatRoomId: string = '';
    ownMemberId: string = '';
    ownStreamId: string = '';

    hungUp: boolean = false;

    constructor() {
        this.jsonSocket = new JsonSocket();
        this.jsonSocket.setOnConnected(() => {
            console.log('connected!');
        });
        this.jsonSocket.setOnDisconnected(() => {
            if (this.keepAliveInterval) {
                clearInterval(this.keepAliveInterval);
            }
            if (!this.hungUp) {
                this.jsonSocket.connect(process.env.REACT_APP_WSS_API_URL!);
            }
        });
        this.jsonSocket.setOnUnknownCommand((requestId: number, _command: string, _params: any) => {
            this.jsonSocket.sendResponse(requestId, -10, {
                errorMsg: 'Unknown command',
            });
        });
        this.jsonSocket.registerCommand('messageFromChatRoomMember', async (requestId: number, command: string, params: any) => {
            const { message } = params;
            if (message) {
                if (message.type === 'offer') {
                    const answer = await callController.createAnswer(message.streamId, message.offer);
                    await this.sendMessageToChatRoomMember(params.fromMemberId, {
                        type: 'answer',
                        answer,
                        streamId: message.streamId,
                    });
                } else if (message.type === 'answer') {
                    await callController.receivedAnswer(message.streamId, message.answer);
                } else if (message.type === 'iceCandidate') {
                    await callController.receivedIceCandidate(message.streamId, message.candidate);
                } else if (message.type === 'shareScreenOffer') {
                    console.log('shareScreenOffer', params);
                    store.dispatch(
                        addSharedStream({
                            streamId: message.streamId,
                            memberId: params.fromMemberId,
                        }),
                    );
                    const answer = await callController.createAnswer(message.streamId, message.offer, true);
                    await this.sendMessageToChatRoomMember(params.fromMemberId, {
                        type: 'shareScreenAnswer',
                        answer,
                        streamId: message.streamId,
                    });
                } else if (message.type === 'shareScreenAnswer') {
                    await callController.receivedAnswer(message.streamId, message.answer);
                } else if (message.type === 'shareScreenClosed') {
                    store.dispatch(
                        removeSharedStream({
                            streamId: message.streamId,
                            memberId: params.fromMemberId,
                        }),
                    );
                } else if (message.type === 'requestSharedScreen') {
                    console.log('requestSharedScreen', params);
                    this.sendShareScreen(params.fromMemberId, message.streamId);
                } else if (message.type === 'hangUp') {
                    console.log('hangUp', params);
                    this.remoteHungUp();
                } else if (message.type === 'declined') {
                    console.log('hangUp', params);
                    this.remoteHungUp();
                }
            }

            this.jsonSocket.sendResponse(requestId, 0, {});
        });
    }

    connect() {
        this.jsonSocket.connect(process.env.REACT_APP_WSS_API_URL!);
    }

    createChatRoom(pid: string, uid: string) {
        this.jsonSocket.setOnConnected(() => {
            console.log('connected!');
            let notifyUserId1 = 'pharmacy_' + pid,
                notifyUserId2 = 'user_' + uid,
                participants: {}[] = [{ notifyUserId: notifyUserId1 }, { notifyUserId: notifyUserId2 }];
            this.jsonSocket.sendCommand('createChatRoom', { participants: participants, dynamic: false, appType: 'amamed' }, (json: any) => {
                console.log('VIDEOCALL SERVER response', json);
                if (json.errorCode === 0) {
                    const videoCallLink = json.chatRoomUrl + '&tk=' + json.loginTokens[0];
                    console.log('videoCallLink', videoCallLink);
                    store.dispatch(setVideoLink(videoCallLink));
                }
            });
        });
        this.jsonSocket.connect(process.env.REACT_APP_WSS_API_URL!);
    }

    enterChatRoom() {
        this.jsonSocket.setOnConnected(() => {
            this.jsonSocket.sendCommand(
                'enterChatRoom',
                {
                    chatRoomId: this.chatRoomId,
                    loginToken: this.loginToken,
                },
                (result: any) => {
                    callController.turnUsername = result.turnUsername;
                    callController.turnPassword = result.turnPassword;

                    this.ownStreamId = result.ownStreamId;
                    this.ownMemberId = result.ownMemberId;

                    console.log('enterChatRoom result', util.inspect(result, { depth: 10 }));

                    if (result.errorCode === 0) {
                        const callMembers: CallMember[] = [],
                            sharedScreenIds: { memberId: string; streamId: string }[] = [];
                        for (let i = 0; i < result.members.length; i++) {
                            const member = result.members[i],
                                callMember: CallMember = {
                                    username: member.username,
                                    hasAudio: false,
                                    hasVideo: false,
                                    sharedScreens: [],
                                    sendingSharedScreens: [],
                                    streamId: composeStreamId(this.ownMemberId, member.memberId),
                                    memberId: member.memberId,
                                };
                            callMembers.push(callMember);
                            for (let j = 0; j < member.sharedScreenIds.length; j++) {
                                sharedScreenIds.push({
                                    memberId: member.memberId,
                                    streamId: member.sharedScreenIds[j],
                                });
                            }
                        }
                        store.dispatch(setMembers({ members: callMembers }));
                        const { members } = store.getState().call;

                        for (let i = 0; i < members.length; i++) {
                            const member = members[i];
                            this.callMember(member);
                        }
                        console.log('sharedScreenIds', sharedScreenIds);
                        for (let i = 0; i < sharedScreenIds.length; i++) {
                            console.log('request stream', sharedScreenIds[i]);
                            this.requestSharedScreen(sharedScreenIds[i].memberId, sharedScreenIds[i].streamId);
                        }
                    }
                },
            );
            this.keepAliveInterval = setInterval(() => {
                this.jsonSocket.sendCommand('keepAlive', {}, (result: any) => {
                    console.log('sent keepAlive', result);
                });
            }, 55000);
        });
        this.jsonSocket.connect(process.env.REACT_APP_WSS_API_URL!);
    }

    remoteHungUp() {
        this.hungUp = true;
        callController.closeAll();
        this.jsonSocket.connection.close();
    }

    async hangUp() {
        this.hungUp = true;
        callController.closeAll();
        const { members } = store.getState().call;

        for (let i = 0; i < members.length; i++) {
            const member = members[i];
            await this.sendMessageToChatRoomMember(member.memberId, {
                type: 'hangUp',
            });
        }

        this.jsonSocket.connection.close();
    }

    async shareScreen() {
        const stream = await callController.shareScreen();
        if (stream) {
            store.dispatch(
                addLocalSharedStream({
                    streamId: stream.streamId,
                    mediaStream: stream.mediaStream,
                }),
            );
            const sendToMembers = await this.sendCommand('chatRoomAddSharedScreen', {
                streamId: stream.streamId,
            });
            console.log('sendToMembers', sendToMembers);
            if (sendToMembers.errorCode === 0) {
                for (let i = 0; i < sendToMembers.memberIds.length; i++) {
                    store.dispatch(
                        addSendingSharedStream({
                            memberId: sendToMembers.memberIds[i],
                            streamId: `${sendToMembers.memberIds[i]}_${stream.streamId}`,
                        }),
                    );
                    this.sendShareScreen(sendToMembers.memberIds[i], stream.streamId);
                }
            }
        }
    }

    closeShareScreen(streamId: string) {
        callController.stopStream(streamId);
        store.dispatch(removeLocalSharedStream({ streamId }));
        const { members } = store.getState().call;
        for (let i = 0; i < members.length; i++) {
            store.dispatch(
                removeSendingSharedStream({
                    memberId: members[i].memberId,
                    streamId: `${members[i].memberId}_${streamId}`,
                }),
            );
            this.sendMessageToChatRoomMember(members[i].memberId, {
                type: 'shareScreenClosed',
                streamId: `${members[i].memberId}_${streamId}`,
            });
        }
    }

    async callMember(member: CallMember) {
        const offer = await callController.createOffer(member.streamId);
        this.sendMessageToChatRoomMember(member.memberId, {
            type: 'offer',
            offer,
            streamId: member.streamId,
        });
    }

    requestSharedScreen(memberId: string, streamId: string) {
        this.sendMessageToChatRoomMember(memberId, {
            type: 'requestSharedScreen',
            streamId,
        });
    }

    async sendShareScreen(memberId: string, streamId: string) {
        if (callController.streamIdExists(streamId)) {
            console.log('send share screen!');
            const offer = await callController.createOffer(`${memberId}_${streamId}`, streamId);
            this.sendMessageToChatRoomMember(memberId, {
                type: 'shareScreenOffer',
                offer,
                streamId: `${memberId}_${streamId}`,
            });
        } else {
            console.log("share screen don't exists", memberId, streamId);
        }
    }

    sendCommand(command: string, params: any): Promise<any> {
        return new Promise<any>((fulfilled) => {
            this.jsonSocket.sendCommand(command, params, (result: any) => {
                fulfilled(result);
            });
        });
    }

    sendMessageToChatRoomMember(memberId: string, message: any): Promise<any> {
        return this.sendCommand('sendMessageToChatRoomMember', {
            chatRoomId: this.chatRoomId,
            memberId,
            message,
        });
    }
}

const connectionController = new ConnectionController();
export default connectionController;
