import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fade from '../transitions/fade';
import { RootState } from '../../store';
import { secondsAsTime } from '../../shared/helpers/functions';
import connectionController from '../../shared/controllers/ConnectionController';
import { setShowIsCalling } from '../../store/actions/view';

interface Props {}

const InfoView: React.FC<Props> = (_props: Props) => {
    let showStartCall = useSelector((state: RootState) => state.view.showStartCall),
        showIsCalling = useSelector((state: RootState) => state.view.showIsCalling),
        showCallEnded = useSelector((state: RootState) => state.view.showCallEnded),
        callLength = useSelector((state: RootState) => state.view.callLength),
        visible = showStartCall || showIsCalling || showCallEnded,
        text = '';
    if (showCallEnded) {
        text = 'Anruf wurde beendet.';
        if (callLength) {
            text += '\n\nDauer: ' + secondsAsTime(Math.ceil(callLength / 1000));
        }
    } else if (showIsCalling) {
        text = 'Kunde wird angerufen.\n\nBitte warten.';
    } else if (showStartCall) {
      text = 'Jetzt Konferenz starten.';
    }

    const dispatch = useDispatch();

    return (
        <Fade
            visible={visible}
            style={{
                position: 'absolute',
                top: 120,
                left: '50%',
                transform: 'translate(-50%, 0)',
                backgroundColor: '#000000',
                padding: 20,
                borderRadius: 20,
            }}
        >
            <div style={{ color: '#ffffff', fontSize: 20, whiteSpace: 'pre-wrap', textDecoration: showStartCall ? 'underline' : undefined, cursor: showStartCall ? 'pointer' : undefined }} onClick={() => {
              if (showStartCall) {
                dispatch(setShowIsCalling(true));
                connectionController.enterChatRoom();
              }
            }
            }>{text}</div>
        </Fade>
    );
};

export default InfoView;
