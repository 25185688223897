import React, { CSSProperties } from 'react';
import './imageButton.css';

interface Props {
    image: string;
    style?: CSSProperties;
    alt?: string;
    onClick?: () => void;
}

const ImageButton: React.FC<Props> = (props: Props) => (
    <div
        style={{
            ...{
                height: 80,
                width: 80,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
            },
            ...props.style,
        }}
        className={'ImageButton'}
        onClick={props.onClick}
    >
        <img src={props.image} alt={props.alt} />
    </div>
);

export default ImageButton;
