import React from 'react';
import './App.css';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import Welcome from './components/views/welcome';
import CallLarge from './components/views/callLarge';
import CallSmall from './components/views/callSmall';
import callController from './shared/controllers/CallController';
import { setLocalMediaStream, setRemoteMediaStream } from './shared/store/actions/call';
import { RootState, store } from './store';
import { getMemberFromStreamId } from './shared/store/functions/call';
import connectionController from './shared/controllers/ConnectionController';
import { setLocalAudioEnabled, setLocalVideoEnabled } from './shared/store/actions/devices';
import InfoView from './components/views/infoView';
import { setShowCallEnded, setShowIsCalling } from './store/actions/view';

function App() {
    const isDesktopOrLaptop = useMediaQuery({
            query: '(min-width: 1224px)',
        }),
        showWelcome = useSelector((state: RootState) => state.view.showWelcome);

    return (
        <div className="App">
            {isDesktopOrLaptop && (
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        height: '100%',
                    }}
                >
                    <Welcome width={420} visible={showWelcome} />
                    <CallLarge />
                    <InfoView />
                </div>
            )}
            {!isDesktopOrLaptop && (
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        height: '100%',
                    }}
                >
                    <CallSmall />
                    <Welcome visible={showWelcome} width={showWelcome ? undefined : window.innerWidth} />
                    <InfoView />
                </div>
            )}
        </div>
    );
}

callController.on('localStream', (data) => {
    console.log('localStream!', data);
    store.dispatch(setLocalMediaStream(data.mediaStream));
});

callController.on('remoteStream', (data) => {
    console.log('remoteStream!', data);
    store.dispatch(
        setRemoteMediaStream({
            streamId: data.connectionId,
            mediaStream: data.remoteStream,
        }),
    );
});

callController.on('remoteStreamTrack', (data) => {
    console.log('remoteStreamTrack!', data);
    const callState = store.getState().call;
    for (let i = 0; i < callState.members.length; i++) {
        const member = callState.members[i];
        if (member.streamId === data.connectionId && member.mediaStream) {
            console.log('callState.members[i].mediaStream', member.mediaStream);
            member.mediaStream.addTrack(data.track);
            return;
        }
    }
});

callController.on('icecandidate', (data) => {
    const member = getMemberFromStreamId(data.connectionId);
    if (member) {
        connectionController.sendMessageToChatRoomMember(member.memberId, {
            type: 'iceCandidate',
            candidate: data.candidate,
            streamId: data.connectionId,
        });
    }
});

callController.on('videoToggled', (params) => {
    store.dispatch(setLocalVideoEnabled(params.enabled));
});

callController.on('audioToggled', (params) => {
    store.dispatch(setLocalAudioEnabled(params.enabled));
});

callController.on('connectionStateChanged', (params) => {
    if (params.connectionState === 'connected') {
        store.dispatch(setShowIsCalling(false));
    }
});

callController.on('callEnded', (params) => {
    store.dispatch(setShowIsCalling(false));
    store.dispatch(setShowCallEnded({ length: params.callStart ? new Date().getTime() - params.callStart.getTime() : undefined }));
});

// callController.setLocalDevices();

export default App;
