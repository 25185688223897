import React, { CSSProperties, useRef } from 'react';
import { Transition } from 'react-transition-group';

const duration = 300;

interface Props {
    visible: boolean;
    style: CSSProperties;
    children?: React.ReactNode;
    width?: number;
}

const defaultStyle = {
    transition: `${duration}ms ease-in-out`,
};

function getTransitionStyle(state: string, width: number): CSSProperties {
    switch (state) {
        case 'entering':
            return { marginLeft: 0 };
        case 'entered':
            return { marginLeft: 0 };
        case 'exiting':
            return { marginLeft: -width };
        case 'exited':
            return { marginLeft: -width };
        default:
            return {};
    }
}

const SlideIn: React.FC<Props> = (props: Props) => {
    const nodeRef = useRef(null);
    return (
        <Transition nodeRef={nodeRef} in={props.visible} timeout={duration}>
            {(state) => (state === 'exited' ? null : (
                <div
                    ref={nodeRef}
                    style={{
                        ...props.style,
                        ...defaultStyle,
                        ...getTransitionStyle(state, props.width || 0),
                    }}
                >
                    {props.children}
                </div>
            ))
            }
        </Transition>
    );
};

export default SlideIn;
