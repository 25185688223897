import React from 'react';
import { useSelector } from 'react-redux';
import Fade from '../transitions/fade';
import ImageButton from '../buttons/imageButton';
import { RootState } from '../../store';
import StreamView from './streamView';
import { toggleFullScreen } from '../../shared/helpers/functions';

interface Props {}

const CallLarge: React.FC<Props> = (_props: Props) => {
    const localMediaStream = useSelector((state: RootState) => state.call.localMediaStream),
        members = useSelector((state: RootState) => state.call.members),
        remoteStream = members && members.length > 0 ? members[0].mediaStream : undefined;

    console.log('REMOTESTREAM', remoteStream, members);
    console.log('MEMBERS', members);

    return (
        <div
            style={{
                backgroundColor: '#cccccc',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
            }}
        >
            <div
                style={{
                    height: 90,
                    backgroundColor: '#053855',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingLeft: 12,
                    paddingRight: 12,
                }}
            >
                <ImageButton
                    image={require('../../assets/images/icon_fullscreen.png')}
                    onClick={() => {
                        toggleFullScreen(document.documentElement);
                    }}
                />
                <div style={{ flex: 1 }} />
                <ImageButton image={require('../../assets/images/icon_more.png')} />
                <ImageButton image={require('../../assets/images/icon_audio.png')} />
                <ImageButton image={require('../../assets/images/icon_camera.png')} />
                <ImageButton image={require('../../assets/images/icon_microphone.png')} />
            </div>
            <div style={{ flex: 1, position: 'relative' }}>
                <Fade
                    duration={1000}
                    visible={remoteStream !== undefined}
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                >
                    <StreamView
                        stream={remoteStream}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: '50%',
                            bottom: 0,
                            transform: 'translate(-50%, 0)',
                            objectFit: 'contain',
                        }}
                    />
                </Fade>
                <Fade
                    duration={1000}
                    visible={localMediaStream !== undefined}
                    style={{
                        position: 'absolute',
                        bottom: 20,
                        right: 20,
                        height: 250,
                    }}
                >
                    <StreamView stream={localMediaStream} muted={true} />
                </Fade>
            </div>
        </div>
    );
};

export default CallLarge;
