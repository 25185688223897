import React, { CSSProperties, useEffect, useRef } from 'react';

interface IProps {
    stream?: MediaStream;
    style?: CSSProperties;
    muted?: boolean;
    removable?: boolean;
    onRemove?: () => void;
}

const StreamView: React.FC<IProps> = (props: IProps) => {
    const videoRef = useRef(null as HTMLVideoElement | null);

    useEffect(() => {
        if (videoRef && videoRef.current) {
            videoRef.current.srcObject = props.stream || null;
        }
    }, [videoRef, props.stream]);

    return (
        <>
            <video
                autoPlay={true}
                playsInline={true}
                controls={false}
                muted={props.muted}
                style={{
                    ...{
                        height: '100%',
                        background: '#000000',
                    },
                    ...props.style,
                }}
                ref={videoRef}
            />
            {props.removable && (
                <div
                    onClick={props.onRemove}
                    style={{
                        cursor: 'pointer',
                        position: 'absolute',
                        top: 2,
                        right: 2,
                        background: '#ff0000',
                        color: '#ffffff',
                        border: 'solid',
                        borderRadius: 12,
                        borderColor: '#ffffff',
                        borderWidth: 1,
                        paddingLeft: 6,
                        paddingRight: 6,
                    }}
                >
                    -
                </div>
            )}
        </>
    );
};

export default StreamView;
