import { createReducer } from '@reduxjs/toolkit';
import { setShowCallEnded, setShowIsCalling, setShowStartCall, setShowWelcome } from '../actions/view';

export interface ViewState {
    showStartCall: boolean;
    showWelcome: boolean;
    showIsCalling: boolean;
    showCallEnded: boolean;
    callLength?: number;
}

const defaultState: ViewState = {
    showStartCall: true,
    showWelcome: true,
    showIsCalling: false,
    showCallEnded: false,
};

export const viewReducer = createReducer(defaultState, (builder) =>
    builder
        .addCase(setShowWelcome, (state, action) => {
            state.showWelcome = action.payload;
        })
      .addCase(setShowStartCall, (state, action) => {
        state.showStartCall = action.payload;
      })
        .addCase(setShowIsCalling, (state, action) => {
            state.showStartCall = false;
            state.showCallEnded = false;
            state.showIsCalling = action.payload;
        })
        .addCase(setShowCallEnded, (state, action) => {
            state.showCallEnded = true;
            state.callLength = action.payload.length;
        }),
);
