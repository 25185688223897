import React from 'react';
import { useSelector } from 'react-redux';
import Fade from '../transitions/fade';
import ImageButton from '../buttons/imageButton';
import StreamView from './streamView';
import { RootState } from '../../store';
import connectionController from '../../shared/controllers/ConnectionController';
import { toggleFullScreen } from '../../shared/helpers/functions';
import callController from '../../shared/controllers/CallController';

interface Props {}

const CallSmall: React.FC<Props> = (_props: Props) => {
    const localMediaStream = useSelector((state: RootState) => state.call.localMediaStream),
        members = useSelector((state: RootState) => state.call.members),
        remoteStream = members && members.length > 0 ? members[0].mediaStream : undefined,
        localAudioEnabled = useSelector((state: RootState) => state.devices.localAudioEnabled),
        localVideoEnabled = useSelector((state: RootState) => state.devices.localVideoEnabled),
        isCalling = useSelector((state: RootState) => state.view.showIsCalling),
        callEnded = useSelector((state: RootState) => state.view.showCallEnded);

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: '#cccccc',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <div style={{ flex: 1, position: 'relative' }}>
                <Fade
                    duration={1000}
                    visible={remoteStream !== undefined}
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                >
                    <StreamView
                        stream={remoteStream}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: '50%',
                            bottom: 0,
                            transform: 'translate(-50%, 0)',
                            width: '100%',
                            objectFit: 'cover',
                        }}
                    />
                </Fade>
                <Fade
                    visible={localMediaStream !== undefined}
                    style={{
                        position: 'absolute',
                        bottom: 100,
                        right: 0,
                        height: 200,
                    }}
                >
                    <StreamView stream={localMediaStream} muted={true} />
                </Fade>
            </div>
            <Fade
                visible={isCalling || !callEnded}
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: 100,
                    backgroundColor: '#05385580',
                    borderTopLeftRadius: 12,
                    borderTopRightRadius: 12,
                    flexDirection: 'row',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <ImageButton
                    style={{ flex: 1 }}
                    image={require('../../assets/images/icon_s_switch.png')}
                    onClick={() => {
                        callController.switchLocalDevice();
                    }}
                />
                <ImageButton
                    style={{ flex: 1 }}
                    image={localVideoEnabled ? require('../../assets/images/icon_activate_camera.png') : require('../../assets/images/icon_deactivate_camera.png')}
                    onClick={() => {
                        callController.toggleVideo();
                    }}
                />
                <ImageButton
                    style={{ flex: 1 }}
                    image={localAudioEnabled ? require('../../assets/images/icon_unmute.png') : require('../../assets/images/icon_mute.png')}
                    onClick={() => {
                        callController.toggleAudio();
                    }}
                />
                <ImageButton
                    style={{ flex: 1 }}
                    image={require('../../assets/images/icon_s_hangup.png')}
                    onClick={() => {
                        connectionController.hangUp();
                    }}
                />
            </Fade>
            <ImageButton
                style={{ position: 'absolute', top: 4, left: 8 }}
                image={require('../../assets/images/icon_fullscreen.png')}
                onClick={() => {
                    toggleFullScreen(document.documentElement);
                }}
            />
        </div>
    );
};

export default CallSmall;
